import {
    faPrescriptionBottle,
    faSchool,
    faMonument,
    faBuilding
} from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import ContentSection from "../../components/contentSection";
import Capabilities from "../../components/home/capabilities";
import Layout from "../../components/layout";
import PageTitle from "../../components/pageTitle";
import SEO from "../../components/seo";
import services from "../../images/services.jpg";
import { Capability } from "../../models";

const capabilityContent: Capability[] = [
    {
        icon: faMonument,
        title: "Federal Government",
        details:
            "Tiber Creek was formed to support the federal government and has provided excellent services for over 25 years.",
        path: "/services/federal"
    },
    {
        icon: faBuilding,
        title: "Commercial",
        details:
            "As a growing business ourselves, we understand the challenges businesses face in the current financial climate.",
        path: "/services/commercial"
    },
    {
        icon: faPrescriptionBottle,
        title: "Tiber Creek Rx",
        details:
            "Our Rx Division is experienced in providing quality and timely Pharmacy Benefit Management (PBM) audits for clients of all types and sizes.",
        path: "/services/rx"
    },
    {
        icon: faSchool,
        title: "Tiber Creek K-12",
        details:
            "Our Education Services Division provides network, server, storage, messaging, and security services to support your educational goals.",
        path: "/services/education"
    }
];

class ServicesHome extends React.Component {
    render() {
        return (
            <Layout>
                <SEO title="Services" />

                <PageTitle title="Services" imageUrl={services} />

                <ContentSection title="Who We Serve">
                    We understand each customer’s need presents a unique
                    challenge. From start to finish, we stand by our customers,
                    and together we create an efficient and strategic plan of
                    action. We deliver customized, scalable, cutting edge
                    technological solutions to our customers in a timely
                    fashion. Over the years, Tiber Creek Consulting has
                    successfully served a variety of clients including the
                    Department of Defense, Health Care, K-12 Education systems,
                    and many more.
                </ContentSection>

                <div className="mb-5">
                    <ContentSection backgroundColor="dark" textColor="white">
                        <Capabilities capabilities={capabilityContent} />
                    </ContentSection>
                </div>
            </Layout>
        );
    }
}

export default ServicesHome;
